import { api_url } from '@/api/thg/api';
import { TokenType } from '@/enums/TokenType';
import { processActionPayload } from '@/utils/thg';
import { HttpResponse, post } from '@/api/Api';
import { THGInfoMailPayload } from '@/interfaces/thg/THGInfoMailPayload';
import { THGActionResponse } from '@/interfaces/thg/THGActionResponse';

const USERS_ACTION_PATH = '/crm/users-actions';

/**
 * Trigger info mail sending action.
 *
 * @param payload
 * @return response
 */
export async function triggerInfoMailSending(
    payload: THGInfoMailPayload,
): Promise<HttpResponse<THGActionResponse>> {
    const processedPayload = processActionPayload(payload);

    return await post<THGActionResponse>(
        `${api_url()}${USERS_ACTION_PATH}/send-info-mail`,
        processedPayload,
        TokenType.THG_API_KEY,
    );
}
