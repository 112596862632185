export enum THGActionType {
    INFOMAIL = 'infomail',
    REGISTRATION_DECLINE = 'registration-decline', // registration declined
    SUBMISSION = 'submission',
    RECEIVING = 'receiving',
    DECLINE = 'decline', // declined by 'Umweltbundesamt'
    PAYOUT = 'payout',
    OFFER_INSTANT_PAYOUT = 'offer-instant-payout',
    REMOVE_OFFER_INSTANT_PAYOUT = 'no-longer-offer-instant-payout',
    OVERRIDE_INSTANT_PAYOUT_AMOUNT = 'override-instant-payout-amount',
    OVERRIDE_CO2_EQUIVALENT = 'override-co2-equivalent',
    OVERRIDE_STATUS_DATA_RECEIVED = 'override-status-data-received',
    OVERRIDE_STATUS_CERT_RECEIVED = 'override-status-cert-received',
    ENERGY_USE_NOT_MEASURABLE = 'energy-use-not-measurable',
}
